* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

body {
  font-family: Arial, sans-serif;
  background-color: #fff;
  /* padding: 24px; */
}
img#getpageavtarlogo {
  position: fixed;
  /* top: 0; */
  /* left: 5px; */
  /* width: 200px; */
  padding: 15px;
  /* max-width: 216px; */
}
#face_detection_location{
  position: fixed;
  /* top: 0; */
  /* left: 5px; */
  /* width: 200px; */
  padding: 15px;
  /* max-width: 216px; */
}
.copyright_section span {
  font-size: 32px;
  position: fixed;
  bottom: 22px;
  right: 34px;
  font-weight: 700;
}

/* Loader wrapper styling */
.loader-wrapper {
 
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: none;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

/* Loader styling */
.loader {
  width: 50px;
  height: 50px;
  border: 5px solid #f3f3f3;
  border-top: 5px solid #3498db;
  border-radius: 50%;
  animation: spin 1s linear infinite;
}

/* Loader animation */
@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}


/* Hide content initially */
body.hide #sec1 {
  display: none;
}



/*End*/
.videoSectionWrap.avtarsection{
  display: none;
}
/* .ai_concierge {
  width: 18%;
} */
#recording_start{
  display: none;
}
#stoprecording{
  display: none;
}

button.ai_concierge {
  background: #0F4C9C;
  width: 100%;
}
button.ai_starttalk{
  background: red;
  margin-bottom: 10px;
  
}
button.ai_starttalk:hover{
  background: red;
  
}
button.ai_concierge:hover {
  background: #0F4C9C;
  width: 100%;
}

.main {
  display: flex;
  flex-direction: column;
/*  gap: 24px;*/
}
.videoWrap img {
  /* width: 500px; */
}
.actionRowsWrap {
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.actionRow {
  display: flex;
  align-items: center;
  /* flex-wrap: wrap; */
  justify-content: center;
  gap: 12px;
}

.actionRow label {
  display: flex;
  align-items: center;
  gap: 8px;
}

.language_sec {
  display: flex;
  gap: 10px;
  margin-top: 6%;
}


  button {
    display: inline-block;
    padding: 0 16px;
    border-radius: 8px;
    height: 56px;
    background-color: #4caf50;
    border: none;
    color: #fff;
    text-align: center;
    font-size: 25px;
    cursor: pointer;
    transition-duration: 0.4s;
    font-weight: bold;
}


button:hover {
  background-color: #45a049;
}

input {
  height: 40px;
  padding: 0 12px;

  font-size: 16px;
}

#status {
  overflow: auto;

  background-color: #fff;
  height: 120px;
  padding: 10px 12px;

  border: 1px solid #ccc;
  border-radius: 8px;

  font-size: 14px;
  line-height: 1.6;
}


.videoSectionWrap {
  position: relative;

  display: flex;
  flex-direction: column;
  align-items: center;
/*  gap: 12px;*/
}

.actionRow.switchRow {
  width: 100%;

  justify-content: center;
}
.switchRow {
  flex-direction: column;
}
.switchRow > label {
  width: 100%;

  display: flex;
  justify-content: center;
}

.switchRow > label input {
  flex: 1;
  max-width: 500px;
}

.videoSectionWrap .videoWrap {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  width: 100%;

  /*background: linear-gradient(0deg, rgba(0, 0, 0, 0.02) 0%, rgba(0, 0, 0, 0.02) 100%),*/
  /*  radial-gradient(*/
  /*    108.09% 141.42% at 0% 100%,*/
  /*    rgba(219, 255, 213, 0.3) 0%,*/
  /*    rgba(255, 255, 255, 0) 100%*/
  /*  ),*/
  /*  linear-gradient(135deg, #ffeede 5.71%, #ffd9d9 47%, #a3dce7 93.47%);*/
}

.videoWrap .videoEle {
  width: 100%;
}
.imagebuttons {
  position: absolute;
  /* width: 22%; */
  top: 18%;
  right: 5%;
  text-align: center;
}
/*---------- Switch START ----------*/
.switchWrap {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
}

.switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.slider:before {
  position: absolute;
  content: '';
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

input:checked + .slider {
  background-color: #2196f3;
}

input:focus + .slider {
  box-shadow: 0 0 1px #2196f3;
}

input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}
/*---------- Switch END ----------*/

.videoSectionWrap .hide {
  display: none;
}

.videoSectionWrap .show {
  display: flex;
}


.show {
  display: flex;
}


a#ai_live_chat {
  
  color: #fff;
  padding: 16px 24px;
  border-radius: 8px;
  height: 56px;
  display: inline-block;
  text-decoration: none;
  font-size: 25px;
}
@media only screen and (max-width: 980px) {
  /* .copyright_section {
    display: none;
  } */
}

div#back_to_menu button {
  position: fixed;
  bottom: 27px;
  left: 19px;
  border-radius: 0;
  /* padding: 42px; */
}
div#textresponse {
  max-width: 300px;
  font-size: 44px;
  font-weight: 700;
}
